import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
	Alert,
	Button,
	DialogActions,
	DialogContent,
	Stack,
} from "@mui/material";
import { toast } from "react-toastify";
import { useQuotationDetailStore } from "../store/QuotationDetailStore";
import { CheckCircle } from "@mui/icons-material";
import DialogForm from "../../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../../components/Dialog/Buttons/ApplyButton";
import {
	parseNumberToDecimal,
	sumObjectArrayByKey,
} from "../../../../../utils/NumberOperations";
import { QuotationBaseEditableDetails } from "../interfaces/QuotationBaseEditableDetails";

type QuotationApproveButtonProps = {
	fetchDetails: () => Promise<void>;
	baseEditableFields: QuotationBaseEditableDetails;
};
const QuotationApproveButton: React.FC<QuotationApproveButtonProps> = ({
	fetchDetails,
	baseEditableFields,
}) => {
	const { t } = useTranslation(["quotation", "general"]);

	const [dialogVisible, setDialogVisible] = React.useState(false);
	const [errorMsgs, setErrorMsgs] = React.useState<Array<string>>([]);
	const hasValidationErrors = errorMsgs.length > 0;

	const {
		sendToWorkflowApproval,
		setLoading,
		readOnlyDetails,
		editableDetails,
		hasChanges,
		glosaFieldsEditableDetails,
		glosaTextEditableDetails,
	} = useQuotationDetailStore();

	const validateApproval = () => {
		validateReservations();
		validatePaymentTerms();
		validateMandatoryFields();
	};

	const validatePaymentTerms = () => {
		const paymentTermsSum = sumObjectArrayByKey(
			editableDetails.paymentTerms,
			"value"
		);
		const paymentTermRetomaSum = sumObjectArrayByKey(
			editableDetails.paymentTermRetoma.paymentRetomaList,
			"value"
		);

		const totalPaymentTermsSum = parseNumberToDecimal(
			paymentTermsSum + paymentTermRetomaSum,
			2
		);

		let totalPrice = editableDetails.priceAndPayment.totalPrice;

		if (readOnlyDetails.currency === "USD") {
			if (readOnlyDetails.currentExchangeRate <= 0) {
				setErrorMsgs((arr) => [
					...arr,
					t("approveValidation.missingCurrentExchangeRate"),
				]);
				return;
			}
			totalPrice = parseNumberToDecimal(
				totalPrice * readOnlyDetails.currentExchangeRate,
				2
			);
		}

		if (totalPaymentTermsSum < totalPrice) {
			setErrorMsgs((arr) => [
				...arr,
				t("approveValidation.paymentTermsSumLesserThenTotalPrice", {
					paymentTermsSum: totalPaymentTermsSum,
					totalPrice: totalPrice,
				}),
			]);
			return;
		}

		if (totalPaymentTermsSum > totalPrice) {
			setErrorMsgs((arr) => [
				...arr,
				t("approveValidation.paymentTermsSumBiggerThenTotalPrice", {
					paymentTermsSum: totalPaymentTermsSum,
					totalPrice: totalPrice,
				}),
			]);
			return;
		}
	};

	const validateReservations = () => {
		const reservationsCount = editableDetails.reservations.filter(
			(f) => f.factoryOrder
		).length;

		if (reservationsCount !== readOnlyDetails.quantity)
			setErrorMsgs((arr) => [
				...arr,
				t("approveValidation.missingReservations", {
					qtd: readOnlyDetails.quantity,
					informedQtd: reservationsCount,
				}),
			]);
	};

	const validateMandatoryFields = () => {
		if (!glosaFieldsEditableDetails.bodyBuilderType)
			setErrorMsgs((arr) => [
				...arr,
				t("approveValidation.bodyBuilderTypeRequired"),
			]);

		if (
			!glosaFieldsEditableDetails.loadWeight ||
			glosaFieldsEditableDetails.loadWeight <= 0
		)
			setErrorMsgs((arr) => [
				...arr,
				t("approveValidation.loadWeightRequired"),
			]);

		if (
			!glosaFieldsEditableDetails.modelYear ||
			glosaFieldsEditableDetails.modelYear <= 1900
		)
			setErrorMsgs((arr) => [...arr, t("approveValidation.modelYearRequired")]);

		if (
			!glosaFieldsEditableDetails.vehicleWeight ||
			glosaFieldsEditableDetails.vehicleWeight <= 0
		)
			setErrorMsgs((arr) => [
				...arr,
				t("approveValidation.vehicleWeightRequired"),
			]);

		if (!baseEditableFields.rut)
			setErrorMsgs((arr) => [...arr, t("approveValidation.rutRequired")]);

		if (!glosaTextEditableDetails.glosa) {
			if (!glosaFieldsEditableDetails.approved) {
				setErrorMsgs((arr) => [
					...arr,
					t("approveValidation.glosaAndApprovalRequired"),
				]);
			} else {
				setErrorMsgs((arr) => [...arr, t("approveValidation.glosaRequired")]);
			}
		} else if (!glosaFieldsEditableDetails.approved) {
			setErrorMsgs((arr) => [
				...arr,
				t("approveValidation.glosaMustBeApproved"),
			]);
		}
	};

	const handleApproveSubmit = async () => {
		if (errorMsgs.length > 0) return;

		setLoading(true);
		await sendToWorkflowApproval()
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data))
			.finally(() => fetchDetails());
	};

	const setDialogOpen = () => {
		setErrorMsgs([]);
		validateApproval();
		setDialogVisible(true);
	};

	return (
		<RenderButtons
			setDialogOpen={setDialogOpen}
			hasChanges={hasChanges}
			t={t}
			dialogVisible={dialogVisible}
			setDialogVisible={setDialogVisible}
			readOnlyDetails={readOnlyDetails}
			hasValidationErrors={hasValidationErrors}
			errorMsgs={errorMsgs}
			handleApproveSubmit={handleApproveSubmit}>
		</RenderButtons>
	);
};

const RenderButtons: React.FC<any> = ({
	setDialogOpen, hasChanges, t, dialogVisible, setDialogVisible, readOnlyDetails, hasValidationErrors, errorMsgs, handleApproveSubmit
}) => (
	<>
		<Button
			variant="contained"
			color="success"
			onClick={setDialogOpen}
			startIcon={<CheckCircle />}
			disabled={hasChanges}
			sx={{ marginRight: 2 }}
		>
			{t("approve", { ns: "general" })}
		</Button>
		<DialogForm
			id="quotation-approve-dialog"
			open={dialogVisible}
			title={t("confirmationMsg", { ns: "general" })}
			onClose={() => setDialogVisible(false)}
			maxWidth="lg"
		>
			<DialogContent dividers>
				<Stack spacing={2}>
					<span>
						{t("approveValidation.approveQuotationMsg", {
							quotationId: readOnlyDetails.quotationId,
						})}
					</span>
					{hasValidationErrors ? (
						<>
							<span>{t("approveValidation.approveQuotationErrorMsg")}</span>
							{errorMsgs.map((errorMsg: any) => (
								<Alert severity="error">{errorMsg}</Alert>
							))}
						</>
					) : (
						<Alert severity="warning">
							{t("approveValidation.approveQuotationWarningMsg")}
						</Alert>
					)}
				</Stack>
			</DialogContent>
			<DialogActions>
				<CancelButton
					id="quotation-approve-cancelbtn"
					onCancel={() => setDialogVisible(false)}
					title={t("cancel", { ns: "general" })}
				/>
				<ApplyButton
					id="quotation-approve-applybtn"
					onSubmit={handleApproveSubmit}
					title={t("confirm", { ns: "general" })}
					startIcon={<CheckCircle />}
					color="success"
					disabled={hasValidationErrors}
				/>
			</DialogActions>
		</DialogForm>
	</>
)

export default observer(QuotationApproveButton);

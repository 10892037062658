import { toJS } from "mobx";
import { PaymentTermsProps } from "../pages/Quotations/Chile/Details/interfaces/PaymentTermsProps";
import { SelectOptionProps } from "./GenericInterfaces";

export const getLabel = (paymentTerm: PaymentTermsProps, language: string) => {
	switch (language) {
		case "pt-BR":
			return paymentTerm.descriptionPtBr === ""
				? paymentTerm.descriptionEnUs
				: paymentTerm.descriptionPtBr;
		case "es-ES":
			return paymentTerm.descriptionEsEs === ""
				? paymentTerm.descriptionEnUs
				: paymentTerm.descriptionEsEs;
		default:
			return paymentTerm.descriptionEnUs;
	}
};

export const getValueGrid = (language: string) => {
	switch (language) {
		case "pt-BR":
			return "paymentTerms.descriptionPtBr";
		case "es-ES":
			return "paymentTerms.descriptionEsEs";
		default:
			return "paymentTerms.descriptionEnUs";
	}
};

export const getSortValueGrid = (language: string) => {
	return "orderMCManagementDetail." + getValueGrid(language);
};

export const getPaymentTermsOptions = (
	data: Array<any>,
	language: string
): Array<SelectOptionProps> => {
	if (!data) {
		return [];
	}

	return toJS(data).map(
		(m: any) =>
			({
				label: getLabel(m, language),
				value: m.id,
			}) as SelectOptionProps
	);
};

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import {
	Button,
	Checkbox,
	DialogActions,
	DialogContent,
	FormControlLabel,
	Grid,
} from "@mui/material";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import { Restore, Save } from "@mui/icons-material";
import { useQuotationDetailStore } from "../../store/QuotationDetailStore";
import { useAuthStore } from "../../../../../../auth/store/AuthStore";
import {
	groupsAllowedToEditGlosaFields,
	groupsAllowedToEditGlosaText,
} from "../../../../../../constants/AuthGroups/quotationChilePage";
import DialogForm from "../../../../../../components/Dialog/DialogForm";
import CancelButton from "../../../../../../components/Dialog/Buttons/CancelButton";
import ApplyButton from "../../../../../../components/Dialog/Buttons/ApplyButton";
import { GlosaSectionProps } from "../../interfaces/Sections/GlosaSection";

const GlosaSection: React.FC<GlosaSectionProps> = ({
	handleSubmit,
	editable,
}) => {
	const { t } = useTranslation(["quotation", "general"]);
	const { isAuthorizedGroup } = useAuthStore();

	const {
		glosaReadOnlyDetails,
		glosaFieldsEditableDetails,
		initialGlosaFieldsEditableDetails,
		glosaTextEditableDetails,
		setGlosaFieldsEditableFieldByKey,
		setGlosaTextEditableFieldByKey,
	} = useQuotationDetailStore();

	const setGlosaDefaultValue = () => {
		const glosa = t("chileanGlosa", {
			...glosaReadOnlyDetails,
			...glosaFieldsEditableDetails,
			vehicleWeightPer1000: glosaFieldsEditableDetails.vehicleWeight
				? glosaFieldsEditableDetails.vehicleWeight / 1000
				: "",
			ns: "general",
		});

		setGlosaTextEditableFieldByKey("glosa", glosa);
	};

	const glosaApproveCheckBoxOnClick = (checked: boolean) => {
		setGlosaFieldsEditableFieldByKey("approved", checked);
		setGlosaDialogVisible(true);
	};

	const glosaApproveCheckBoxOnCancelClick = () => {
		setGlosaFieldsEditableFieldByKey(
			"approved",
			initialGlosaFieldsEditableDetails.approved
		);
		setGlosaDialogVisible(false);
	};

	const canEditGlosaText =
		editable &&
		!glosaFieldsEditableDetails.approved &&
		glosaReadOnlyDetails.isWorkflowStatusPermitedToEditGlosa &&
		isAuthorizedGroup(groupsAllowedToEditGlosaText);

	const canEditGlosaFields =
		editable &&
		glosaReadOnlyDetails.isWorkflowStatusPermitedToEditGlosa &&
		isAuthorizedGroup(groupsAllowedToEditGlosaFields);

	const [glosaDialogVisible, setGlosaDialogVisible] = React.useState(false);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.glosa.title")}
			</Grid>
			<Grid item xs={3}>
				<TextForm
					editable={canEditGlosaFields}
					id={"vehicleWeight"}
					label={t("details.sections.glosa.vehicleWeight")}
					value={glosaFieldsEditableDetails.vehicleWeight}
					onChange={(e) =>
						setGlosaFieldsEditableFieldByKey(
							"vehicleWeight",
							parseInt(e.target.value)
						)
					}
					type="number"
					inputProps={{ min: 0 }}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextForm
					editable={canEditGlosaFields}
					id={"loadWeight"}
					label={t("details.sections.glosa.loadWeight")}
					value={glosaFieldsEditableDetails.loadWeight}
					onChange={(e) =>
						setGlosaFieldsEditableFieldByKey(
							"loadWeight",
							parseInt(e.target.value)
						)
					}
					type="number"
					inputProps={{ min: 0 }}
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.glosa.bodyBuilderType")}
					editable={canEditGlosaFields}
					value={glosaFieldsEditableDetails.bodyBuilderType}
					inputProps={{ maxLength: 200 }}
					onChange={(e) =>
						setGlosaFieldsEditableFieldByKey("bodyBuilderType", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={2}>
				<TextForm
					editable={canEditGlosaFields}
					id={"modelYear"}
					label={t("details.sections.glosa.modelYear")}
					value={glosaFieldsEditableDetails.modelYear}
					onChange={(e) =>
						setGlosaFieldsEditableFieldByKey(
							"modelYear",
							parseInt(e.target.value)
						)
					}
					type="number"
					inputProps={{ min: 0, max: 9999, maxLength: 4 }}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextForm
					label={t("details.sections.glosa.title")}
					editable={canEditGlosaText}
					value={glosaTextEditableDetails.glosa}
					multiline
					maxRows={20}
					inputProps={{ maxLength: 2000 }}
					onChange={(e) =>
						setGlosaTextEditableFieldByKey("glosa", e.target.value)
					}
				/>
			</Grid>
			<Grid item justifyContent="end" alignItems="end">
				<Button
					variant="contained"
					color="secondary"
					disabled={!canEditGlosaText}
					onClick={setGlosaDefaultValue}
				>
					<Restore /> {t("restore", { ns: "general" })}
				</Button>
			</Grid>
			<Grid item justifyContent="end" alignItems="end">
				<FormControlLabel
					control={
						<Checkbox
							checked={glosaFieldsEditableDetails.approved}
							color="secondary"
							disabled={!canEditGlosaFields}
							onChange={(e) => glosaApproveCheckBoxOnClick(e.target.checked)}
							style={{ padding: 6 }}
						/>
					}
					label={t("details.sections.glosa.approve")}
				/>
			</Grid>
			<DialogForm
				id="quotation-approve-glosa-dialog"
				open={glosaDialogVisible}
				title={t("confirmationMsg", { ns: "general" })}
				onClose={glosaApproveCheckBoxOnCancelClick}
			>
				<DialogContent dividers>
					{t("saveAllConfirmationMsg", { ns: "general" })}
				</DialogContent>
				<DialogActions>
					<CancelButton
						id="quotation-approve-glosa-cancelbtn"
						onCancel={glosaApproveCheckBoxOnCancelClick}
						title={t("cancel", { ns: "general" })}
					/>
					<ApplyButton
						id="quotation-approve-glosa-applybtn"
						onSubmit={handleSubmit}
						title={t("confirm", { ns: "general" })}
						startIcon={<Save />}
						color="warning"
					/>
				</DialogActions>
			</DialogForm>
		</>
	);
};

export default observer(GlosaSection);

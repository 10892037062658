import { CTADMVEN_QUOTATION_ARG } from "../../../constants/routes";
import { MenuRouteProps } from "../../../utils/GenericInterfaces";
import { groupsAllowedToSeeArgentinaQuotationMenu } from "../../../constants/AuthGroups/quotationArgentinaPage";
import { ReceiptLong } from "@mui/icons-material";
import MainList from "./List/containers/MainList";
import _ from "lodash";
import { AuthGroupsType } from "../../../constants/AuthGroups/authGroups";
import MenuListSubHeader from "../../../components/header/MenuListSubHeader";
import { getMenuAuthorized } from "../../../utils/RoutesUtil";
import { TFunction } from "i18next";

export const quotationsArgentinaAllClaims: Array<AuthGroupsType> = _.uniq([
	...groupsAllowedToSeeArgentinaQuotationMenu,
]);

export const quotationArgentinaMenuRoute: MenuRouteProps = {
	path: CTADMVEN_QUOTATION_ARG,
	claims: groupsAllowedToSeeArgentinaQuotationMenu,
	element: <MainList />,
	label: "quotation.argentina.title",
	isCollapsable: true,
};

export function getQuotationsArgentinaMenus(
	t: TFunction<"menu", undefined>,
	isAuthorizedGroup: (claims: AuthGroupsType[]) => boolean,
	open: boolean
) {
	if (!isAuthorizedGroup(quotationsArgentinaAllClaims)) {
		return <></>;
	}

	return (
		<>
			<MenuListSubHeader drawerOpen={open} text={t("argentina")} />
			{getMenuAuthorized(quotationArgentinaMenuRoute, t, isAuthorizedGroup)}
		</>
	);
}

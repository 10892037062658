import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { CustomerSectionProps } from "../../interfaces/Sections/CustomerSection";
import TextForm from "../../../../../components/FormInputs/TextForm";
import SelectForm from "../../../../../components/FormInputs/SelectForm";
import { getChileZoneOptions } from "../../../../../utils/SelectOptions";

const CustomerSection: React.FC<CustomerSectionProps> = ({
	readOnlyFields,
	editableFields,
	setFieldByKeyOnChange,
}) => {
	const { t } = useTranslation(["order", "general"]);

	const zoneOptions = getChileZoneOptions(t);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.customer.title")}
				{readOnlyFields.saleStatus && " - " + readOnlyFields.saleStatus}
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.originalCustomer")}
					value={readOnlyFields.foOriginalCustomerOM}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.sasCustomer")}
					value={editableFields.finalCustomer}
					editable
					name="FinalCustomer"
					inputProps={{ maxLength: 100 }}
					onChange={(e) =>
						setFieldByKeyOnChange("finalCustomer", e.target.value)
					}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.bodybuilder")}
					value={editableFields.bodybuilder}
					editable
					name="Bodybuilder"
					inputProps={{ maxLength: 50 }}
					onChange={(e) => setFieldByKeyOnChange("bodybuilder", e.target.value)}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextForm
					label={t("details.sections.customer.salesman")}
					value={readOnlyFields.salesman}
					name="Salesman"
				/>
			</Grid>
			<Grid item xs={6}>
				<SelectForm
					label={t("details.sections.customer.zone")}
					labelId={"sections.customer-zone-label"}
					value={editableFields.zoneId}
					options={zoneOptions}
					editable
					hasEditIcon
					hasEmptyDefaultItem
					onChange={(e) =>
						setFieldByKeyOnChange("zoneId", e?.target?.value as number | undefined)
					}
				/>
			</Grid>
		</>
	);
};

export default observer(CustomerSection);

import * as React from "react";
import { useTranslation } from "react-i18next";
import {
	NavLink as RouterLink,
	NavLinkProps as RouterLinkProps,
} from "react-router-dom";
import { Home, Traffic } from "@mui/icons-material";
import LockClockIcon from "@mui/icons-material/LockClock";
import { useAuthStore } from "../auth/store/AuthStore";
import {
	exportPartnerRoute,
	factoryDeliveriesMenuCollapsableRoute,
	orderBookBusMenuCollapsableRoute,
	orderBookTruckMenuCollapsableRoute,
} from "../pages/Order/routes";
import { quotaMenuCollapsableRoute } from "../pages/Quota/routes";
import { parametersMenuCollapsableRoute } from "../pages/Parameters/routes";
import {
	followUpArgentinaAllClaims,
	getFollowUpArgentinaMenus,
} from "../pages/FollowUpArg/routes";
import { inconsistenciesMenuRoute } from "../pages/Inconsistencies/routes";
import {
	followUpChlAllClaims,
	getFollowUpChlMenus,
} from "../pages/FollowUpChl/routes";
import {
	closedPeriodArgAllClaims,
	getClosedPeriodArgMenus,
} from "../pages/ClosedPeriodArg/routes";
import MenuListItem from "../components/header/MenuListItem";
import MenuCollapsableListItems from "../components/header/MenuCollapsableListItems";
import MenuListSubHeader from "../components/header/MenuListSubHeader";
import { downloadCenterMenuRoute } from "../pages/DownloadCenter/routes";
import {
	getMenuAuthorized,
	getMenuCollabsableAuthorized,
} from "../utils/RoutesUtil";
import { getQuotationsArgentinaMenus, quotationsArgentinaAllClaims } from "../pages/Quotations/Argentina/routes";
import { getQuotationsChileMenus, quotationsChileAllClaims } from "../pages/Quotations/Chile/routes";

interface ListItemsProps {
	open: boolean;
}
interface MenuLinkProps {
	primaryText: string;
	secondaryText?: string;
	icon?: React.ReactElement;
	isCollapsable?: boolean;
	to: string;
}

function getRouterLink(
	itemProps: Omit<RouterLinkProps, "to">,
	ref: React.ForwardedRef<HTMLAnchorElement>,
	linkTo: string
) {
	return (
		<RouterLink
			to={linkTo}
			ref={ref}
			style={({ isActive }) => ({
				backgroundColor: isActive ? "whitesmoke" : "",
				borderRight: isActive ? "4px solid #202A44" : "",
				color: "black",
			})}
			{...itemProps}
		/>
	);
}

function getForwardRef(linkTo: string) {
	return React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(
		(itemProps, ref) => getRouterLink(itemProps, ref, linkTo)
	);
}

export const MenuLink: React.FC<MenuLinkProps> = ({
	primaryText,
	secondaryText,
	icon,
	isCollapsable,
	to,
}) => {
	const renderLink = React.useMemo(() => getForwardRef(to), [to]);

	return (
		<MenuListItem
			primaryText={primaryText}
			secondaryText={secondaryText}
			icon={icon}
			isCollapsable={isCollapsable}
			component={renderLink}
		/>
	);
};

export const MenuItems: React.FC<ListItemsProps> = ({ open }) => {
	const { t } = useTranslation("menu");
	const { isAuthorizedGroup } = useAuthStore();

	return (
		<>
			<MenuLink primaryText={t("home")} icon={<Home />} to={""} />
			{getMenuCollabsableAuthorized(
				orderBookTruckMenuCollapsableRoute,
				t,
				isAuthorizedGroup,
				open
			)}
			{getMenuCollabsableAuthorized(
				orderBookBusMenuCollapsableRoute,
				t,
				isAuthorizedGroup,
				open
			)}
			{getMenuCollabsableAuthorized(
				factoryDeliveriesMenuCollapsableRoute,
				t,
				isAuthorizedGroup,
				open
			)}
			{isAuthorizedGroup([
				...followUpArgentinaAllClaims,
				...followUpChlAllClaims,
			]) && (
					<MenuCollapsableListItems
						mainPrimaryText={t("followup.title")}
						icon={<Traffic />}
						listItems={
							<>
								{getFollowUpArgentinaMenus(t, isAuthorizedGroup, open)}
								{getFollowUpChlMenus(t, isAuthorizedGroup, open)}
							</>
						}
						drawerOpen={open}
					/>
				)}
			{isAuthorizedGroup(closedPeriodArgAllClaims) && (
				<MenuCollapsableListItems
					mainPrimaryText={t("closedPeriod.title")}
					icon={<LockClockIcon />}
					listItems={
						<>
							<MenuListSubHeader drawerOpen={open} text={t("argentina")} />
							{getClosedPeriodArgMenus(t, isAuthorizedGroup)}
						</>
					}
					drawerOpen={open}
				/>
			)}
			{getMenuAuthorized(exportPartnerRoute, t, isAuthorizedGroup)}
			{getMenuCollabsableAuthorized(
				quotaMenuCollapsableRoute,
				t,
				isAuthorizedGroup,
				open
			)}
			{getMenuCollabsableAuthorized(
				parametersMenuCollapsableRoute,
				t,
				isAuthorizedGroup,
				open
			)}
			{isAuthorizedGroup([
				...quotationsArgentinaAllClaims,
				...quotationsChileAllClaims,
			]) && (
					<MenuCollapsableListItems
						mainPrimaryText={t("quotation.title")}
						icon={<Traffic />}
						listItems={
							<>
								{getQuotationsArgentinaMenus(t, isAuthorizedGroup, open)}
								{getQuotationsChileMenus(t, isAuthorizedGroup, open)}
							</>
						}
						drawerOpen={open}
					/>
				)}
			{getMenuAuthorized(inconsistenciesMenuRoute, t, isAuthorizedGroup)}
			{getMenuAuthorized(downloadCenterMenuRoute, t, isAuthorizedGroup)}
		</>
	);
};

import React from "react";
import { Routes, Route } from "react-router-dom";
import NotFoundError from "../components/msal/NotFoundError";
import Home from "./Home/Home";
import * as AuthGroups from "../constants/AuthGroups/authGroups";
import ExportPartnerHomePage from "./ExportPartner/containers/ExportPartnerIndex";
import { orderRoutes, europeFactoryRoutes } from "./Order/routes";
import ProtectedRoute from "../components/ProtectedRoute/ProtectedRoute";
import { CTADMVEN_EXPORT_PARTNER } from "../constants/routes";
import { quotaRoutes } from "./Quota/routes";
import { parametersRoutes } from "./Parameters/routes";
import { quotationChileRoutes } from "./Quotations/Chile/routes";
import { quotationArgentinaMenuRoute } from "./Quotations/Argentina/routes";
import { followUpArgentinaRoutes } from "./FollowUpArg/routes";
import { inconsistenciesRoutes } from "./Inconsistencies/routes";
import { followUpChileRoutes } from "./FollowUpChl/routes";
import { closedPeriodArgRoutes } from "./ClosedPeriodArg/routes";
import { downloadCenterRoutes } from "./DownloadCenter/routes";

export interface AppRouteProps {
	path: string;
	element: React.ReactNode;
}

const AppRoutes: React.FC = () => {
	return (
		<Routes>
			<Route path="/" element={<Home />} />
			{orderRoutes.map((route: AppRouteProps, idx: number) => (
				<Route path={route.path} element={route.element} key={`order-${idx}`} />
			))}
			{followUpArgentinaRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`FollowUpArg-${idx}`}
				/>
			))}
			{followUpChileRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`followUpChl-${idx}`}
				/>
			))}
			{closedPeriodArgRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`FollowUpArg-${idx}`}
				/>
			))}
			<Route
				path={CTADMVEN_EXPORT_PARTNER}
				element={
					<ProtectedRoute
						element={<ExportPartnerHomePage />}
						claims={[AuthGroups.BRA_SALES_SUPPORT_EXPORT, AuthGroups.ADMIN]}
					/>
				}
			/>
			{quotaRoutes.map((route: AppRouteProps, idx: number) => (
				<Route path={route.path} element={route.element} key={`quota-${idx}`} />
			))}
			{europeFactoryRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`europeFactory-${idx}`}
				/>
			))}
			{parametersRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`parameters-${idx}`}
				/>
			))}
			<Route
				path={quotationArgentinaMenuRoute.path}
				element={
					<ProtectedRoute
						element={quotationArgentinaMenuRoute.element}
						claims={quotationArgentinaMenuRoute.claims}
					/>
				}
			/>
			{quotationChileRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`quotation-${idx}`}
				/>
			))}
			{inconsistenciesRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`inconsistencies-${idx}`}
				/>
			))}
			{downloadCenterRoutes.map((route: AppRouteProps, idx: number) => (
				<Route
					path={route.path}
					element={route.element}
					key={`download-${idx}`}
				/>
			))}
			<Route path="*" element={<NotFoundError />} />
		</Routes>
	);
};

export default AppRoutes;

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { useParams } from "react-router";
import { Box, Grid } from "@mui/material";
import { toast } from "react-toastify";
import { LoadingPage } from "../../../../../components/LoadingPage/LoadingPage";
import { TitleBox } from "../../../../../components/TitleBox/TitleBox";
import NoContentResult from "../../../../../components/msal/NoContentResult";
import { useQuotationDetailStore } from "../store/QuotationDetailStore";
import GeneralAccordion from "./Accordions/GeneralAccordion";
import VehicleAccordion from "./Accordions/VehicleAccordion";
import QuotationCommentsAccordion from "./Accordions/QuotationCommentsAccordion";
import SaveBar from "../../../../../components/SaveBar/SaveBar";
import QuotationRestoreCancelButton from "./QuotationRestoreCancelButton";
import VehicleCustomizationAccordion from "./Accordions/VehicleCustomizationAccordion";
import PriceAndPaymentAccordion from "./Accordions/PriceAndPaymentAccordion";
import TruckReservationAccordion from "./Accordions/TruckReservationAccordion";
import { useAuthStore } from "../../../../../auth/store/AuthStore";
import {
	groupsAllowedToEditQuotationReservation,
	groupsAllowedToSendToWorkflowApproval,
	groupsQuotationManager,
	groupsQuotationSalesman,
} from "../../../../../constants/AuthGroups/quotationChilePage";
import QuotationGoToSharePointButton from "./QuotationGoToSharePointButton";
import { QuotationStatusSASEnum } from "../../../../../utils/enums/QuotationStatusSASEnum";
import QuotationApproveButton from "./QuotationApproveButton";
import { QuotationWorkflowStatusEnum } from "../../../../../utils/enums/QuotationWorkflowStatusEnum";
import { getEnumKeyByValue } from "../../../../../utils/enums/EnumMethods";

const QuotationDetailIndex: React.FC = () => {
	const { t } = useTranslation(["ctadmven", "menu", "general", "quotation"]);
	const { id } = useParams<{ id: string }>();

	const { isAuthorizedGroup } = useAuthStore();

	const isMarketCompanyUser = isAuthorizedGroup(
		groupsAllowedToEditQuotationReservation
	);
	const isQuotationManagerUser = isAuthorizedGroup(groupsQuotationManager);
	const isQuotationSalesmanUser = isAuthorizedGroup(groupsQuotationSalesman);
	const isAllowedToEditReservation = isAuthorizedGroup(
		groupsAllowedToEditQuotationReservation
	);

	const {
		readOnlyDetails,
		loadDetails,
		editableDetails,
		managerEditableDetails,
		setLocalCustomizationComment,
		setQuotationManagerEditableField,
		salesmanOptions,
		hasChanges,
		update,
		hasErrors,
		loading,
		setLoading,
		salesmanEditableDetails,
		setQuotationSalesmanEditableField,
		financialCustomerOptions,
		loadFinancialCustomer,
		setQuotationBaseEditableField,
		paymentTermsOptions,
		isFinancialCustomer,
		setIsFinancialCustomer,
	} = useQuotationDetailStore();

	const fetchDetails = React.useCallback(async () => {
		setLoading(true);
		await loadDetails(id ?? "", isQuotationManagerUser).finally(() =>
			setLoading(false)
		);
	}, [id, isQuotationManagerUser, loadDetails, setLoading]);

	const handleSubmit = async () => {
		if (
			editableDetails.paymentTerms.some(
				(x) =>
					x.id ===
					paymentTermsOptions.filter((x) => x.descriptionEnUs === "LEASING")[0]
						.id
			) &&
			!baseEditableFields.financialCustomerCode
		) {
			if (canEditCustomerDetails) {
				toast.warning(
					t("paymentTermLeasingRequiresFinancialInstitution", {
						ns: "quotation",
					}).toString()
				);
				setIsFinancialCustomer(true);
			} else {
				toast.warning(
					t("currentQuotationStatusDontAllowThisChange", {
						ns: "quotation",
					}).toString()
				);
			}

			return;
		}

		setLoading(true);
		await update(isAllowedToEditReservation)
			.then(() =>
				toast.success(t("editRecordWithSuccess", { ns: "general" }).toString())
			)
			.catch((err: any) => toast.error(err.response.data))
			.finally(() => fetchDetails());
	};

	React.useEffect(() => {
		fetchDetails();
	}, [fetchDetails]);

	const breadcrumbs = [
		{
			title: t("home", { ns: "menu" }),
			href: "/",
			main: false,
		},
		{
			title: t("quotation.title", { ns: "menu" }),
			href: "#/quotation",
			main: false,
		},
		{
			title: t("quotation.details", { ns: "menu" }),
			main: true,
		},
	];

	const isCanceledQuotation =
		readOnlyDetails.statusSAS === QuotationStatusSASEnum.Canceled;

	const isRequestedApprovalQuotation =
		readOnlyDetails.workflowStatus ===
		getEnumKeyByValue(
			QuotationWorkflowStatusEnum.Requested,
			QuotationWorkflowStatusEnum
		);

	const userIsAllowedToSendToWorkflowApproval = isAuthorizedGroup(
		groupsAllowedToSendToWorkflowApproval
	);

	const baseEditableFields = isQuotationSalesmanUser
		? { ...salesmanEditableDetails }
		: { ...managerEditableDetails };

	const isApprovedQuotation =
		readOnlyDetails.workflowStatus ===
		getEnumKeyByValue(
			QuotationWorkflowStatusEnum.Approved,
			QuotationWorkflowStatusEnum
		);

	const isRejectedQuotation =
		readOnlyDetails.workflowStatus ===
		getEnumKeyByValue(
			QuotationWorkflowStatusEnum.Rejected,
			QuotationWorkflowStatusEnum
		);

	const canEditCustomerDetails =
		!isRequestedApprovalQuotation &&
		!isCanceledQuotation &&
		!isApprovedQuotation;

	return (
		<>
			<LoadingPage loading={loading} />
			{!loading && readOnlyDetails.quotationId && (
				<>
					<Box
						p={1}
						display="flex"
						justifyContent="flex-end"
						alignItems="flex-end"
						gap="1em"
					>
						<TitleBox breadcrumbs={breadcrumbs} />
						{userIsAllowedToSendToWorkflowApproval &&
							readOnlyDetails.isPermittedToSendWorkflowToApproval && (
								<QuotationApproveButton
									fetchDetails={fetchDetails}
									baseEditableFields={baseEditableFields}
								/>
							)}
						{(readOnlyDetails.isPermittedToCancel || isCanceledQuotation) && (
							<QuotationRestoreCancelButton fetchDetails={fetchDetails} />
						)}
						<QuotationGoToSharePointButton />
					</Box>
					<Box p={1}>
						<Grid container spacing={2}>
							<Grid item xs mb={25}>
								<Grid container spacing={2}>
									<GeneralAccordion
										generalDetails={{
											readOnlyFields: { ...readOnlyDetails },
											editable:
												!isRequestedApprovalQuotation &&
												isQuotationManagerUser &&
												!isCanceledQuotation &&
												!isApprovedQuotation &&
												!isRejectedQuotation,
											managerEditableFields: { ...managerEditableDetails },
											salesmanOptions: salesmanOptions,
											setQuotationManagerEditableField:
												setQuotationManagerEditableField,
										}}
										customerDetails={{
											readOnlyFields: { ...readOnlyDetails },
											editable: canEditCustomerDetails,
											isQuotationSalesmanUser: isQuotationSalesmanUser,
											baseEditableFields: { ...baseEditableFields },
											setQuotationSalesmanEditableField,
											setQuotationBaseEditableField,
											financialCustomerOptions,
											loadFinancialCustomer,
											isFinancialCustomer,
											setIsFinancialCustomer,
										}}
									/>
									<TruckReservationAccordion
										truckReservationDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										reservationSectionProps={{
											editable:
												isMarketCompanyUser &&
												!isRequestedApprovalQuotation &&
												!isCanceledQuotation &&
												!isApprovedQuotation,
										}}
										adelantoSectionProps={{
											fetchDetails,
											isAllowedToEditReservation,
											editable:
												!isRequestedApprovalQuotation &&
												!isCanceledQuotation &&
												!isApprovedQuotation,
										}}
										adelanto={readOnlyDetails.adelanto}
									/>
									<VehicleAccordion
										modelDetails={{ readOnlyFields: { ...readOnlyDetails } }}
										variantsDetails={{ readOnlyFields: { ...readOnlyDetails } }}
									/>
									<VehicleCustomizationAccordion
										factoryOptionalDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
										customizationsDetails={{
											editableFields: editableDetails?.customizations,
											setLocalCustomizationComment,
											editable:
												!isRequestedApprovalQuotation &&
												!isCanceledQuotation &&
												!isApprovedQuotation &&
												!isRejectedQuotation,
											currency: readOnlyDetails.currency,
											currentExchangeRate: readOnlyDetails.currentExchangeRate,
										}}
									/>
									<PriceAndPaymentAccordion
										priceAndPaymentDetails={{
											editable: isRejectedQuotation,
										}}
										paymentTermsSectionProps={{
											editable:
												!isRequestedApprovalQuotation &&
												!isCanceledQuotation &&
												!isApprovedQuotation,
										}}
										glosaSectionProps={{
											handleSubmit,
											editable:
												!isRequestedApprovalQuotation &&
												!isCanceledQuotation &&
												!isApprovedQuotation,
										}}
										paymentTermRetomaSectionProps={{
											editable:
												!isRequestedApprovalQuotation &&
												!isCanceledQuotation &&
												!isApprovedQuotation,
										}}
									/>
									<QuotationCommentsAccordion
										finalDetailDetails={{
											readOnlyFields: { ...readOnlyDetails },
										}}
									/>
								</Grid>
							</Grid>
							<SaveBar
								onResetClick={() => fetchDetails()}
								resetLabel={t("undoChanges", { ns: "general" })}
								onSaveClick={() => handleSubmit()}
								saveLabel={t("saveChanges", { ns: "general" })}
								hasFormChanges={hasChanges}
								disabled={hasErrors}
							/>
						</Grid>
					</Box>
				</>
			)}
			{!loading && !readOnlyDetails.quotationId && <NoContentResult />}
		</>
	);
};

export default observer(QuotationDetailIndex);

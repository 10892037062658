import CtadmvenApi from "../../apis/CtadmvenApi";
import { UpdateAllQuotationPaymentTerms } from "../../pages/Quotations/Chile/Details/interfaces/UpdateAllQuotationPaymentTerms";

interface IPaymentTermsService {
	getPaymentTerms(country?: string): Promise<any>;
	getPaymentTermRetoma(id: string): Promise<any>;
	updateQuotationPaymentTerms(
		allPaymentTerms: UpdateAllQuotationPaymentTerms
	): Promise<any>;
}
const base = "/PaymentTerms";

export class PaymentTermsService implements IPaymentTermsService {
	constructor(private api: CtadmvenApi) {}

	getPaymentTerms = async (country?: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}?country=${country}`);

	getPaymentTermRetoma = async (id: string): Promise<any> =>
		this.api.ctadmvenClient.get(`${base}/retoma/${id}`);

	updateQuotationPaymentTerms = async (
		allPaymentTerms: UpdateAllQuotationPaymentTerms
	): Promise<any> =>
		this.api.ctadmvenClient.put(
			`${base}/quotation`,
			JSON.stringify(allPaymentTerms)
		);
}

import React from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { Grid } from "@mui/material";
import { GeneralSectionProps } from "../../interfaces/Sections/GeneralSection";
import TextForm from "../../../../../../components/FormInputs/TextForm";
import DateForm from "../../../../../../components/FormInputs/DateForm";
import SelectForm from "../../../../../../components/FormInputs/SelectForm";
import {
	getQuotationStatusSASOptions,
	getQuotationWorkflowStatusOptions,
} from "../../../../../../utils/SelectOptions";
import AutoCompleteForm from "../../../../../../components/FormInputs/AutoCompleteForm";
import { QuotationLogComponent } from "./Component/QuotationLogComponent";

const GeneralSection: React.FC<GeneralSectionProps> = ({
	readOnlyFields,
	editable,
	managerEditableFields,
	salesmanOptions,
	setQuotationManagerEditableField,
}) => {
	const { t } = useTranslation(["quotation", "general"]);
	const quotationStatusSASOptions = getQuotationStatusSASOptions(t);
	const quotationWorkflowStatusOptions = getQuotationWorkflowStatusOptions(t);

	return (
		<>
			<Grid className="accordionSubTitle" item xs={12}>
				{t("details.sections.general.title")}
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.quotationId")}
					value={readOnlyFields.quotationId}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.general.statusSAS")}
					value={readOnlyFields.statusSAS}
					options={quotationStatusSASOptions}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.general.quotationDate")}
					value={readOnlyFields.quotationDate}
					inputFormat="dd/MM/yy"
				/>
			</Grid>
			<Grid item xs={4}>
				<TextForm
					label={t("details.sections.general.dealerShip")}
					value={readOnlyFields.dealerShipName}
				/>
			</Grid>
			<Grid item xs={4}>
				<AutoCompleteForm
					label={t("details.sections.general.salesman")}
					labelId={"details.sections.general.salesman"}
					value={managerEditableFields.salesmanId}
					options={salesmanOptions}
					editable={editable}
					hasEmptyDefaultItem
					onChange={(value) => {
						setQuotationManagerEditableField("salesmanId", value);
					}}
					isCustomSearch
					hasEditIcon
					editTooltip={t("tooltips.salesmanSearch")}
				/>
			</Grid>
			<Grid item xs={4}>
				<SelectForm
					label={t("details.sections.general.workflowStatus")}
					value={readOnlyFields.workflowStatus}
					options={quotationWorkflowStatusOptions}
				/>
			</Grid>
			<Grid item xs={4}>
				<DateForm
					label={t("details.sections.general.lastUpdate")}
					value={readOnlyFields.lastUpdate}
					inputFormat="dd/MM/yy HH:mm"
				/>
			</Grid>
			<Grid item xs={8}></Grid>
			<Grid item xs={12}>
				<QuotationLogComponent />
			</Grid>
		</>
	);
};

export default observer(GeneralSection);
